.block-text-bild-teaser {
    .wrapper {
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: #{map-get($breakpoints, m)}) {
            flex-wrap: wrap; }

        &.show-element {
            &:before {
                content: "";
                background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_2.svg');
                background-repeat: no-repeat;
                background-size: contain;
                height: 310px;
                width: 310px;
                position: absolute;
                bottom: -100px;
                transform:  rotate(0deg);
                z-index: 1;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    height: 190px;
                    width: 190px; } } }


        &.row-right {
            flex-direction: row-reverse;

            .bg-weiss {
                padding-left: 0; }

            &:before {
                right: -10%; }

            .h1-light {
                left: 4rem;
                top: -15rem;
                z-index: 1;

                @media (max-width: #{map-get($breakpoints, xl)}) {
                    top: -8rem; }

                @media (max-width: #{map-get($breakpoints, m)}) {
                    top: -7rem; }

                @media (max-width: #{map-get($breakpoints, s)}) {
                    top: -5rem;
                    left: 2rem; } } }

        &.row-left {
            flex-direction: row;

            .bg-weiss {
                padding-right: 0; }

            &:before {
                left: -10%; }

            .h1-light {
                position: absolute;
                right: 4rem;
                top: -15rem;
                z-index: 1;

                @media (max-width: #{map-get($breakpoints, xl)}) {
                    top: -8rem; }

                @media (max-width: #{map-get($breakpoints, m)}) {
                    top: -7rem; }


                @media (max-width: #{map-get($breakpoints, s)}) {
                    top: -5rem;
                    right: 2rem; } } }

        .images {
            display: flex;
            flex: 0 0 50%;
            width: 50%;
            position: relative;
            align-self: normal;

            @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%;
                width: 100%; }

            picture {
                display: block;
                width: 100%;
                padding-top: 56.25%;

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } } }

        .content {
            padding: 18 * $base-width 10 * $base-width;
            flex: 0 0 50%;
            width: 50%;
            position: relative;

            @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%;
                width: 100%; }

            &.bg-gruen {
                background-color: $bg-color; }

            &.bg-weiss {
                background-color: $light; }


            @media (max-width: #{map-get($breakpoints, xxxl)}) {
                padding: 14 * $base-width 8 * $base-width; }

            @media (max-width: #{map-get($breakpoints, l)}) {
                padding: 8 * $base-width 6 * $base-width; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                padding: 6 * $base-width 4 * $base-width; }

            h2 {
                @media (max-width: #{map-get($breakpoints, xl)}) {
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -webkit-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto; } } } } }


.block-text-bild-teaser + .block-text-bild-teaser {
    padding-top: 0; }
