button {
  border: 0;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: $font-default; }

.btn {
  margin-top: 3 * $base-width;
  transition: $transition;

  &:hover {
    transition: $transition; }

  &.btn-primary {
    background-color: $secondary;
    padding: 2 * $base-width 4 * $base-width;
    color: $light;
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
    border-radius: 0;
    background-size: 200%;
    background-position: right;

    span {
      color: $light; }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-position: left; } } }

  &.btn-secondary {
    background-color: $secondary;
    padding: 2 * $base-width 3 * $base-width;
    color: $light;
    background: linear-gradient(90deg, rgba($primary,1) 0%, rgba($primary,1) 50%, rgba($secondary,1) 50%, rgba($secondary,1) 100%);
    border-radius: 0;
    background-size: 200%;
    background-position: right;

    &:hover {
      background-color: $primary;
      background-position: left; } }

  &.btn-link {
    position: relative;
    display: inline-flex;
    align-items: center;

    svg {
      fill: $primary;
      height: 2rem;
      width: 2.5rem;
      margin-left: 1rem;
      transition: $transition; }

    &:hover {
      svg {
        transform: translateX(0.5rem);
        transition: $transition; } } } }
