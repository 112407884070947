.woocommerce {
  .woocommerce-MyAccount-navigation {
    margin-bottom: 6 * $base-width;
    padding-top: 18 * $base-width;

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding-top: 12 * $base-width; }

    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;

      li {
        padding: $base-width;
        text-align: center;
        flex: 1 0 0;
        border-bottom: 1px solid $highlight;

        &.is-active {
          border-bottom: 2px solid $primary;

          a {
            color: $primary; } } } } }

  .woocommerce-EditAccountForm {
    fieldset {
      border: 0; }

    legend {
      padding-top: 4 * $base-width;
      font-size: 2.4rem;
      color: $secondary; }

    .woocommerce-Button {
      background-color: $secondary;
      padding: 2 * $base-width 3 * $base-width;
      color: $light;
      background: linear-gradient(90deg, rgba($primary,1) 0%, rgba($primary,1) 50%, rgba($secondary,1) 50%, rgba($secondary,1) 100%);
      border-radius: 0;
      background-size: 200%;
      background-position: right;
      transition: $transition;

      &:hover {
        background-color: $primary;
        background-position: left;
        color: $light;
        transition: $transition; } } }


  .woocommerce-MyAccount-content {
    padding: 0 16 * $base-width 18 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 0 12 * $base-width 14 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 0 5 * $base-width 11 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 0 3 * $base-width 9 * $base-width; } }

  .woocommerce-orders-table {
    .btn {
      margin-top: 0;
      margin-right: 2 * $base-width;

      @media (max-width: #{map-get($breakpoints, s)}) {
        margin-bottom: $base-width;
        margin-top: $base-width;
        margin-right: 0;
        width: 100%;
        text-align: center; } }


    .button.button.alt {
      background-color: $secondary; }

    .woocommerce-button.button {
      background-color: $secondary; } }

  .woocommerce-Address {
    padding-top: 3 * $base-width;
    .edit {
      color: $primary;
      margin-bottom: $base-width; } }

  .woocommerce-table {
    a.button {
      background-color: $secondary;
      color: $light; } }

  input[type="text"],input[type="email"],input[type="password"], select {
    padding: $base-width 2 * $base-width;
    height: 50px;
    border: 1px solid $primary;
    font-family: $font-default;
    border-radius: 0;
    font-size: 1.8rem;
    color: $dark; }

  .select2-container--default .select2-selection--single {
    padding: $base-width 2 * $base-width;
    height: 50px;
    border: 1px solid $primary;
    font-family: $font-default;
    border-radius: 0;
    color: $dark;

    .select2-selection__rendered {
      padding: 0;
      font-size: 1.8rem; }

    .select2-selection__arrow {
      height: 50px; } }

  .woocommerce-shipments-table__cell-shipment-actions {
    a.button {
      background-color: $secondary;
      border-radius: 0;
      color: $light;
      font-family: $font-default;
      font-weight: 400;
      padding: 2 * $base-width 4 * $base-width;
      margin-right: 2 * $base-width; } }

  .woocommerce-shipment-tracking-details {
    a.button {
      background-color: $secondary;
      border-radius: 0;
      color: $light;
      font-family: $font-default;
      font-weight: 400;
      padding: 2 * $base-width 4 * $base-width; } }

  .add_return_shipment_table {
    a.button {
      background-color: $secondary;
      border-radius: 0;
      color: $light;
      font-family: $font-default;
      font-weight: 400;
      padding: 2 * $base-width 4 * $base-width; } }

  #payment {
    #place_order {
      background-color: $primary;
      border-radius: 0;
      color: $light;
      font-family: $font-default;
      font-weight: 400;
      padding: 2 * $base-width 4 * $base-width; } }

  .order-again {
    a.button {
      background-color: $primary;
      border-radius: 0;
      color: $light;
      font-family: $font-default;
      font-weight: 400;
      padding: 2 * $base-width 4 * $base-width; } } }


#order_review {
  max-width: $boxed;
  margin: 0 auto;
  padding: 18 * $base-width 0;

  @media (max-width: #{map-get($breakpoints, xxxl)}) {
    padding: 14 * $base-width 0; }

  @media (max-width: #{map-get($breakpoints, xl)}) {
    padding: 11 * $base-width 0; }

  @media (max-width: #{map-get($breakpoints, s)}) {
    padding: 9 * $base-width 0; }

  ul.wc_payment_methods {
    list-style: none; } }
