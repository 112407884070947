.block-text-bild {

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &.row-right {
            flex-direction: row-reverse;
            // flex-wrap: wrap-reverse

            @media (max-width: #{map-get($breakpoints, xl)}) {
                flex-wrap: wrap-reverse; }

            .content {
                padding-right: 10 * $base-width;

                @media (max-width: #{map-get($breakpoints, xl)}) {
                    padding-right: 6 * $base-width; }

                @media (max-width: #{map-get($breakpoints, m)}) {
                    padding-right: 0;
                    padding-top: 0; } } }

        &.row-left {
            flex-direction: row;

            @media (max-width: #{map-get($breakpoints, xl)}) {
                flex-wrap: wrap-reverse; }

            .content {
                padding-left: 10 * $base-width;

                @media (max-width: #{map-get($breakpoints, xl)}) {
                    padding-left: 6 * $base-width; }

                @media (max-width: #{map-get($breakpoints, m)}) {
                    padding-left: 0;
                    padding-top: 0; } } }

        .images {
            display: flex;

            picture {
                display: block;

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } }

            &.width-1 {
                flex: 0 0 50%;
                position: relative;
                align-self: normal;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; }

                picture {
                    width: 100%;
                    padding-top: 56.25%;

                    @media (max-width: #{map-get($breakpoints, s)}) {
                        padding-top: 100%; } } }


            &.width-2 {
                flex: 0 0 64%;
                padding-top: 3rem;
                padding-bottom: 3rem;

                @media (max-width: #{map-get($breakpoints, l)}) {
                    flex: 0 0 100%; }

                picture {
                    position: relative;
                    width: 50%;
                    padding-top: 66.66%;

                    @media (max-width: #{map-get($breakpoints, l)}) {
                        padding-top: 56.25%; }

                    @media (max-width: #{map-get($breakpoints, s)}) {
                        padding-top: 220px; }


                    &:first-child {
                        transform: translateY(3rem);
                        margin-right: 1.5 * $base-width;

                        @media (max-width: #{map-get($breakpoints, m)}) {
                            margin-right: $base-width;
                            transform: translateY(2rem); } }


                    &:nth-child(2) {
                        transform: translateY(-3rem);
                        margin-left: 1.5 * $base-width;

                        @media (max-width: #{map-get($breakpoints, m)}) {
                            margin-left: $base-width;
                            transform: translateY(-2rem); } } } } }

        .content {
            padding-top: 18 * $base-width;
            padding-bottom: 18 * $base-width;

            @media (max-width: #{map-get($breakpoints, xxxl)}) {
                padding-top: 12 * $base-width;
                padding-bottom: 12 * $base-width; }

            @media (max-width: #{map-get($breakpoints, xl)}) {
                padding-top: 6 * $base-width;
                padding-bottom: 6 * $base-width; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                padding-top: 6 * $base-width;
                padding-bottom: 0; }

            &.width-1 {
                flex: 0 0 50%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    padding-bottom: 4 * $base-width;
                    flex: 0 0 100%; } }

            &.width-2 {
                flex: 0 0 36%;
                padding-top: 6 * $base-width;
                padding-bottom: 6 * $base-width;

                @media (max-width: #{map-get($breakpoints, l)}) {
                    flex: 0 0 100%;
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 0; } } } } }





.block-text-bild + .block-text-bild {
    padding-top: 0; }
