/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  .wrapper {
    max-width: $small;
    margin: 0 auto;

    form {
      .form-inner {
        .form-field-group {
          margin: 4 * $base-width 0;

          label {
            display: inline-block;
            margin-bottom: $base-width; }

          input[type="text"] {
            width: 100%;
            height: 6 * $base-width;
            padding: 2 * $base-width;
            border: 0;
            border: 1px solid $primary;

            &:focus-visible {
              outline: 0; } }

          textarea {
            width: 100%;
            min-height: 15 * $base-width;
            padding: 2 * $base-width;
            border: 1px solid $primary;
            font-family: $font-default;
            font-size: 1.8rem;
            appearance: none;
            -webkit-appearance: none;

            &:focus-visible {
              outline: 0; } }

          .checkbox {
            display: flex;

            #dsgvo {
              width: 25px;
              min-width: 25px;
              min-height: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: $base-width;
              border-color: $primary;

              &:focus-visible {
                outline: 0; }

              &.error {
                &::after {
                  content: '';
                  width: 25px;
                  height: 25px;
                  position: absolute;
                  background-color: rgba($error,.25); }

                &:checked {
                  &::after {
                    background-color: rgba($error,.0); } } } } } }

        #confirm {
          position: absolute;
          left: -20000px; } }

      .form-success,
      .form-error {
        width: 100%;
        // border: 2px solid $error
        background-color: rgba($error,.2);
        padding: 3rem;
        margin-bottom: 3rem; }

      .form-success {
          // border: 2px solid $success
          background-color: rgba($success,.25); }

      .error {
        // border-color: $error
        background-color: rgba($error,.25); } } } }
