.woocommerce {
  .woocommerce-order {
    background-color: $bg-color;
    padding: 18 * $base-width 16 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 14 * $base-width 12 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 11 * $base-width 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 9 * $base-width 3 * $base-width; }

    .h2-light {
      position: relative; }

    .woocommerce-order-overview.woocommerce-thankyou-order-details {
      background-color: $highlight2;
      padding: 4 * $base-width;
      margin-bottom: 8 * $base-width; } }

  .woocommerce-customer-details {
    margin-top: 8 * $base-width;
    background-color: $highlight2;
    padding: 4 * $base-width;

    address {
      border: 0;
      border-radius: 0;
      padding: 0; } }

  .woocommerce-order-details.mollie-instructions {
    display: none; } }
