.block-teaser {
    padding-left: 3 * $base-width;
    padding-right: 3 * $base-width;

    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding-left: 5 * $base-width;
        padding-right: 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        padding-left: 3 * $base-width;
        padding-right: 3 * $base-width; }

    &:before {
        content: "";
        background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_3.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 340px;
        width: 287px;
        position: absolute;
        opacity: 0;
        transform: rotate(-10deg);
        top: -80px;
        right: 0%;
        z-index: 2;
        transition: $transition;

        @media (max-width: #{map-get($breakpoints, l)}) {
            height: 240px;
            width: 240px; }

        @media (max-width: #{map-get($breakpoints, s)}) {
            height: 170px;
            width: 170px;
            transform: rotate(5deg);
            right: -4 * $base-width;
            top: 30px; } }


    &.in-view {
        &:before {
            transition: $transition;
            opacity: 1;
            animation: floating 15s 1 linear;

            @-webkit-keyframes floating {
                0% {
                    transform: rotate(-16deg);
                    top: -240px;
                    opacity: 0; }

                50% {
                    transform: rotate(8deg);
                    opacity: 1; }


                100% {
                    transform: rotate(-10deg);
                    top: -80px;
                    opacity: 1; } }

            @media (max-width: #{map-get($breakpoints, s)}) {
                @-webkit-keyframes floating {
                    0% {
                        transform: rotate(-30deg);
                        top: -140px;
                        opacity: 0; }

                    50% {
                        opacity: 1; }


                    100% {
                        transform: rotate(5deg);
                        top: 30px;
                        opacity: 1; } } } } }


    .wrapper-full {
        max-width: $full;
        margin: 0 auto; }

    .teaser {
        .teaser-item {
            flex: 0 0 25%;

            &:hover {
                .teaser-inner {
                    .content {
                        top: 2.4rem;
                        background-color: rgba($primary, 0.85);
                        transition: $transition;

                        .h4 {
                            &:before {
                                opacity: 0;
                                transition: $transition; } }

                        .text {
                            height: auto;
                            overflow: hidden;
                            opacity: 1;
                            visibility: visible;
                            transition: $transition; } } } }

            .teaser-inner {
                position: relative;

                picture {
                    position: relative;
                    padding-top: 133%;
                    display: block;
                    width: 100%;

                    @media (max-width: #{map-get($breakpoints, xxxl)}) {
                        padding-top: 51rem; }

                    @media (max-width: #{map-get($breakpoints, s)}) {
                        padding-top: 112.5%; }

                    &:after {
                        content: " ";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background: rgb(0,0,0);
                        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                        opacity: 0.28;
                        z-index: 0;
                        transition: $transition; }

                    img {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center; } }

                .content {
                    padding: 2 * $base-width;
                    text-align: center;
                    position: absolute;
                    left: 2.4rem;
                    right: 2.4rem;
                    bottom: 2.4rem;
                    top: 85%;
                    background-color: rgba($primary, 0.0);
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    transition: $transition;

                    .headline {
                        flex: 0 0 100%; }

                    .h4 {
                        margin-bottom: 2 * $base-width;
                        color: $light;
                        position: relative;

                        &:before {
                            content: attr(data-content);
                            font-family: $font-heading;
                            transform: translate(-20px, -20px);
                            color: $light;
                            opacity: 0.24;
                            text-transform: none;
                            font-size: 6rem;
                            position: absolute;
                            left: 3rem;
                            bottom: -0.2rem;
                            letter-spacing: 0;

                            @media (max-width: #{map-get($breakpoints, s)}) {
                                font-size: 4rem; } } }

                    .text {
                        flex: 0 0 100%;
                        height: 0;
                        overflow: hidden;
                        opacity: 0;
                        visibility: hidden;
                        color: $light;
                        transition: $transition;

                        p {
                            padding: 0; } }



                    .btn-link {
                        color: $light;

                        svg {
                            fill: $light; } } } } } } }


.owl-dots {
    text-align: center;
    margin-top: 2 * $base-width;

    button.owl-dot {
        height: 2 * $base-width;
        width: 2 * $base-width;
        background-color: $light;
        border: 1px solid $primary;
        border-radius: 100%;
        margin: $base-width;

        &.active {
            background-color: $primary; } } }
