#intro {
  padding-bottom: 5 * $base-width; }
#search {
  .results {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3 * $base-width;

    .result {
      flex: 0 0 33.333%;
      width: 33%;
      padding: 1.5 * $base-width 3 * $base-width;

      @media (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 50%;
        width: 50%; }

      @media (max-width: #{map-get($breakpoints, s)}) {
        flex: 0 0 100%;
        width: 100%; }

      a {
        display: block;
        position: relative;

        .onsale {
          right: unset;
          left: 3 * $base-width;
          top: 3 * $base-width;
          height: 6.4rem;
          font-weight: 700;
          width: 6.4rem;
          background-color: $sale;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          font-size: 2rem;
          z-index: 1;
          position: absolute;
          border-radius: 100%;
          color: $light;
          text-align: center; }

        &:hover {
          picture {
            span {
              top: 2.4rem;
              background-color: rgba($primary, 0.85);
              transition: $transition;
              opacity: 1; } } }

        picture {
          padding-top: 580px;
          display: block;
          position: relative;

          @media (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 480px; }

          @media (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 400px; }

          &.no-crop {
            border: 1px solid $highlight2;

            img {
              object-fit: contain; } }


          img {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            object-fit: cover; }

          span {
            padding: 2 * $base-width;
            position: absolute;
            left: 3 * $base-width;
            right: 3 * $base-width;
            bottom: 3 * $base-width;
            top: 85%;
            background-color: rgba($primary, 0.0);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            transition: $transition;
            z-index: 2;
            opacity: 0;
            color: $light;
            font-size: 2.4rem;

            svg {
              fill: $light;
              width: 2.4rem;
              height: 2.4rem;
              margin-left: 2 * $base-width; } } }

        .content {
          text-align: center;
          padding: 4 * $base-width 2 * $base-width;

          .h4, .price, .brand {
            color: $dark; }

          .brand {
            margin-bottom: $base-width; }

          .price {
            font-size: 2.4rem;

            del {
              display: none; }

            ins {
              text-decoration: none;

              bdi {
                color: $sale; } } } } } } } }
