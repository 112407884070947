.woocommerce {
  position: relative;

  .woocommerce-notices-wrapper {
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
    right: 0;

    .woocommerce-info {
      background-color: #cd6a6a;
      border: 0;
      margin-bottom: $base-width;
      color: $light;
      text-align: center;

      a {
        color: $light;
        text-decoration: underline;
        background: none;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%; }

        &:hover {
          background: none;
          color: $light; } }

      &:before {
        display: none; } }



    .woocommerce-error {
      background-color: #cd6a6a;
      border: 0;
      margin-bottom: $base-width;
      color: $light;
      text-align: center;
      padding: $base-width 2 * $base-width;

      a {
        color: $light;
        text-decoration: underline;
        background: none;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%; } }

      &:before {
        display: none; } }

    .woocommerce-message {
      background-color: $primary;
      border-top: 0;
      border: 0;
      margin-bottom: $base-width;
      color: $light;
      text-align: center;
      padding: $base-width 2 * $base-width;

      a {
        color: $light;
        text-decoration: underline;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%; } }

      &:before {
        display: none; }

      .button {
        background: 0;
        padding: 0;
        line-height: 2.8rem; } } }

  .woocommerce-error, .woocommerce-info {
    li {
      margin-bottom: 0;
      &::before {
        display: none; } }

    &:before {
      display: none; } }


  .woocommerce-info {
    background-color: $primary;
    border: 0;
    margin-bottom: $base-width;
    color: $light;
    text-align: center;

    a {
      color: $light;
      text-decoration: underline;
      background: none;

      @media (max-width: #{map-get($breakpoints, s)}) {
        width: 100%; }

      &:hover {
        background: none;
        color: $light; } }

    &:before {
      display: none; } }


  .woocommerce-error {
    background-color: #cd6a6a;
    border: 0;
    margin-bottom: 0;
    color: $light;
    text-align: center;
    padding: $base-width 2 * $base-width; }


  .woocommerce-message {
    background-color: $primary;
    border-top: 0;
    border: 0;
    margin-bottom: 0;
    color: $light;
    text-align: center;
    padding: $base-width 2 * $base-width;

    a {
      color: $light;
      text-decoration: underline;

      @media (max-width: #{map-get($breakpoints, s)}) {
        width: 100%; } }

    &:before {
      display: none; }

    .button {
      background: 0;
      padding: 0;
      line-height: 2.8rem; } }

  .cart-empty.woocommerce-info {
    background-color: #E1F1EA;
    color: $dark;
    border: 0;
    text-align: center;

    &:before {
      display: none; } } }
