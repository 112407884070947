.block-gallery {
    padding: 1.5 * $base-width;

    @media (max-width: #{map-get($breakpoints, l)}) {
        padding: $base-width 4 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        padding: $base-width 2 * $base-width; }

    .gallery-grid {
        display: flex;
        flex-wrap: wrap;

        .img-wrapper {
            padding: 1.5 * $base-width;
            position: relative;

            @media (max-width: #{map-get($breakpoints, l)}) {
                padding: $base-width; }

            &:first-child, &:nth-child(6) {
                flex: 1 0 35%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 1 0 33.333%; }

                @media (max-width: #{map-get($breakpoints, s)}) {
                    flex: 1 0 100%; } }

            &:nth-child(2), &:nth-child(4) {
                flex: 1 0 25%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 1 0 33.333%; } }

            &:nth-child(3), &:nth-child(5) {
                flex: 1 0 40%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 1 0 33.333%; } }

            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {

                @media (max-width: #{map-get($breakpoints, s)}) {
                    display: none; } }

            .lightbox {
                display: block; }

            picture {
                position: relative;
                display: block;
                padding-top: 370px;

                @media (max-width: #{map-get($breakpoints, xxxl)}) {
                    padding-top: 270px; }

                @media (max-width: #{map-get($breakpoints, l)}) {
                    padding-top: 220px; }

                @media (max-width: #{map-get($breakpoints, m)}) {
                    padding-top: 180px; }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } }

            .more-btn {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin-top: 0;
                z-index: 1;

                @media (max-width: #{map-get($breakpoints, s)}) {
                    display: block; } }

            .dscr {
                padding: 3 * $base-width 0;

                @media (max-width: #{map-get($breakpoints, xl)}) {
                    display: none; } }

            // &.show-mobile
            //     .more-btn
            //         @media (max-width: #{map-get($breakpoints, s)})
            //             display: none

            //     &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6)
            //         @media (max-width: #{map-get($breakpoints, s)})
            //             flex: 1 0 100%
 } } }            //             display: block


.block-gallery + .block-gallery {
    .h2-light {
        top: -18 * $base-width;

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
            top: -12 * $base-width; }

        @media (max-width: #{map-get($breakpoints, xl)}) {
            top: -10 * $base-width; }

        @media (max-width: #{map-get($breakpoints, s)}) {
            top: -8 * $base-width; } } }
