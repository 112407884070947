.block-logoleiste {
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-top: 0; }

  .wrapper {
    max-width: $boxed;
    margin: 0 auto;

    picture {
      padding-top: 70px;
      position: relative;
      display: block;

      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 120px; }

      img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: contain;
        object-position: center; } } } }


.block-text-icons + .block-logoleiste {
  padding-top: 0; }
