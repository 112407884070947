.woocommerce {
  div.product {
    .woocommerce-tabs {
      padding-top: 4 * $base-width;
      position: relative;

      ul.tabs {
        border-bottom: 1px solid $primary;
        padding: 0;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%; }

        &:before {
          display: none; }

        li {
          border: 0;
          border-radius: 0;
          background-color: #E1F1EA;
          margin-right: 2 * $base-width;

          @media (max-width: #{map-get($breakpoints, s)}) {
            width: 100%;
            margin-right: 0; }

          &:before, &:after {
            display: none; }

          a {
            color: $dark;
            font-size: 1.8rem;
            font-weight: 300;

            @media (max-width: #{map-get($breakpoints, s)}) {
              width: 100%; } }

          &.active {
            background-color: $primary;

            a {
              color: $light; } } } }

      .woocommerce-Tabs-panel {
        ul {
          list-style: none;
          padding-left: 4 * $base-width;

          li {
            position: relative;
            margin-bottom: $base-width;

            &:before {
                content: " ";
                background-color: $primary;
                width: 8px;
                height: 2px;
                position: absolute;
                left: -15px;
                top: 14px; } } }

        .media {
          padding-top: calc(56.25% / 2);
          width: 50%;
          position: relative;

          @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            padding-top: 56.25%;
            flex: 0 0 100%;
            width: 100%; }

          .BorlabsCookie {
            z-index: 9;
            ._brlbs-content-blocker {
              height: 100%; }

            ._brlbs-embed {
                height: 100%;
                width: 100%;

                ._brlbs-caption {
                  z-index: 9; } } }

          iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center; } } } } } }
