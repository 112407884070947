.block-hero-image {
    padding-bottom: 0;
    padding-top: 0;

    &:after {
        content: "";
        background-color: $bg-color;
        height: 50%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1; }

    .wrapper {
        position: relative;

        picture {
            padding-top: 42%;
            position: relative;
            display: block;
            width: 100%;
            background-color: $light;

            @media (max-width: #{map-get($breakpoints, l)}) {
                padding-top: 45%; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                padding-top: 56.25%; }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; }

            &:after {
                content: " ";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                opacity: 0.28;
                z-index: 0;
                transition: $transition; } }

        .content {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1.5rem;
            width: 100%;
            z-index: 1;

            .inner {
                padding: 0 3rem;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    padding: 0 2rem; }

                h1 {
                    color: $light;
                    margin-bottom: 0;
                    font-family: $font-heading;
                    font-size: 12.5rem;
                    line-height: 90%;
                    font-weight: normal;
                    text-shadow: 0 3px 6px rgba(#000, 0.16);

                    @media (max-width: #{map-get($breakpoints, xxl)}) {
                        font-size: 8rem; }

                    @media (max-width: #{map-get($breakpoints, s)}) {
                        font-size: 4rem;
                        line-height: 100%; } } } } } }


.block-hero-image + .block {
    background-color: $bg-color; }


.home {
    .block-hero-image {
        .wrapper {
            &:before {
                content: "";
                background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_1.svg');
                background-repeat: no-repeat;
                background-size: contain;
                height: 287px;
                width: 287px;
                position: absolute;
                bottom: -130px;
                transform:  rotate(-100deg);
                left: -10%;
                z-index: 1;
                animation: fallingHero 8s 1 linear;


                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 150px;
                    width: 150px;
                    left: -5%;
                    bottom: -80px; }

                @media (max-width: #{map-get($breakpoints, s)}) {
                    height: 90px;
                    width: 90px;
                    bottom: -50px; } }

            .content {
                .inner {
                    h1 {
                        font-size: 8rem;
                        line-height: 130%;

                        @media (max-width: #{map-get($breakpoints, xxl)}) {
                            font-size: 6rem; }

                        @media (max-width: #{map-get($breakpoints, l)}) {
                            font-size: 4rem; }

                        @media (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 2.4rem; } } } } } } }


@-webkit-keyframes fallingHero {
    0% {
        transform: rotate(-80deg);
        opacity: 0;
        bottom: 0; }

    25% {
        transform: rotate(-100deg); }

    50% {
        transform: rotate(-85deg);
        opacity: 1; }

    75% {
        transform: rotate(-100deg);
        opacity: 1; }

    100% {
        bottom: -130px; } }

@media (max-width: #{map-get($breakpoints, xxl)}) {
    @-webkit-keyframes fallingHero {
        0% {
            transform: rotate(-80deg);
            opacity: 0;
            bottom: 0; }

        25% {
            transform: rotate(-100deg); }

        50% {
            transform: rotate(-85deg);
            opacity: 1; }

        75% {
            transform: rotate(-100deg);
            opacity: 1; }

        100% {
            bottom: -80px; } } }

@media (max-width: #{map-get($breakpoints, s)}) {
    @-webkit-keyframes fallingHero {
        0% {
            transform: rotate(-80deg);
            opacity: 0;
            bottom: 0; }

        25% {
            transform: rotate(-100deg); }

        50% {
            transform: rotate(-85deg);
            opacity: 1; }

        75% {
            transform: rotate(-100deg);
            opacity: 1; }

        100% {
            bottom: -50px; } } }
