.block-product-teaser {

  &:nth-child(even) {
    background-color: $bg-color; }

  &:nth-child(odd) {
    background-color: $light; }

  // &:first-child, &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9), &:nth-child(11)
  //   background-color: $light

  // &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10)
  //   background-color: $bg-color

  .wrapper-full {
    .h3-light {
      @media (max-width: #{map-get($breakpoints, s)}) {
        display: none; } } }

  .wrapper {
    max-width: $boxed;
    position: relative;

    .images {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid $highlight;
      background-color: $light;

      &.no-border {
        border: 0;
        margin: 0 -2 * $base-width;
        background: none; }

      .image {
        padding: 0 2 * $base-width;
        flex: 1 0 50%;

        @media (max-width: #{map-get($breakpoints, s)}) {
          padding: 2 * $base-width;
          flex: 1 0 100%; }

        .lightbox {
          display: block; }

        picture {
          display: block;
          padding-top: 390px;
          position: relative;

          @media (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 56.25%; }

          img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

            &.cover {
              object-fit: cover; } } } } }

    .content {
      padding-top: 8 * $base-width;
      margin: 0 -8 * $base-width;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: #{map-get($breakpoints, xl)}) {
        margin: 0 -4 * $base-width; }

      .h2 {
        flex: 1 0 100%;
        padding: 0 8 * $base-width;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          padding: 0 4 * $base-width; } }

      .text {
        flex: 0 0 50%;
        padding: 0 8 * $base-width;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          padding: 0 4 * $base-width; }

        @media (max-width: #{map-get($breakpoints, m)}) {
          flex: 0 0 100%;
          padding-bottom: 4 * $base-width; } }

      .accordion {
        flex: 0 0 50%;
        padding: 0 8 * $base-width;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          padding: 0 4 * $base-width; }

        @media (max-width: #{map-get($breakpoints, m)}) {
          flex: 0 0 100%; }

        .accordion-item {
          margin-bottom: 3 * $base-width;

          &:last-child {
            margin-bottom: 0; }

          .accordion-heading {
            background-color: $highlight;
            color: $light;
            transition: $transition;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            overflow: hidden;

            &:hover, &.active {
              background-color: $primary;
              color: $light;
              transition: $transition;

              .svg {
                svg {
                  transform: rotate(180deg);
                  transition: $transition; } } }

            .heading-text {
              padding: 2 * $base-width 3 * $base-width; }

            .svg {
              display: flex;
              align-items: center;
              padding: 2 * $base-width 3 * $base-width;

              svg {
                fill: $light;
                width: 15px;
                height: auto;
                transition: $transition; } } }


          .accordion-body {
            padding: 3 * $base-width;

            ul {
              padding-left: 3 * $base-width; }

            a {
              text-decoration: underline; } } } } } } }
