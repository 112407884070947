.block-text-columns {

    .wrapper {
        max-width: $small;

        &.col-2, .col-3, .col-4 {
            max-width: $boxed; }


        &.element-oben {
            &:before {
                content: "";
                background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_2.svg');
                background-repeat: no-repeat;
                background-size: contain;
                height: 390px;
                width: 390px;
                position: absolute;
                right: 0%;
                top: -240px;
                transform: rotate(-15deg);
                z-index: 1;
                animation: floating0 6s infinite linear;

                @-webkit-keyframes floating0 {
                    0% {
                        transform: translateY(0px) rotate(-15deg); }

                    50% {
                        transform: translateY(25px) rotate(-15deg); }

                    100% {
                        transform: translateY(0px) rotate(-15deg); } }

                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 290px;
                    width: 290px;
                    top: -190px; }

                @media (max-width: #{map-get($breakpoints, l)}) {
                    height: 200px;
                    width: 200px;
                    top: -120px; }

                @media (max-width: #{map-get($breakpoints, m)}) {
                    height: 160px;
                    width: 160px;
                    top: -120px; }

                @media (max-width: #{map-get($breakpoints, s)}) {
                    height: 110px;
                    width: 110px;
                    top: -100px; } } }

        &.element-unten {
            &:after {
                content: "";
                background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_3.svg');
                background-repeat: no-repeat;
                background-size: contain;
                height: 340px;
                width: 287px;
                position: absolute;
                transform: rotate(180deg);
                top: 90%;
                right: 0%;
                z-index: 2;
                transition: $transition;
                animation: floating1 6s infinite linear;

                @media (max-width: #{map-get($breakpoints, xxxxl)}) {
                    height: 290px;
                    width: 290px; }

                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 240px;
                    width: 240px; }

                @media (max-width: #{map-get($breakpoints, s)}) {
                    height: 180px;
                    width: 180px;
                    right: -4 * $base-width;
                    bottom: 0px; }

                @-webkit-keyframes floating1 {
                    0% {
                        transform: translateY(-15px) rotate(180deg); }

                    50% {
                        transform: translateY(5px) rotate(180deg); }

                    100% {
                        transform: translateY(-15px) rotate(180deg); } } } } }
    &.bg-gruen {
        background-color: $bg-color; }

    .h1-light + .wrapper {
        padding-top: 4 * $base-width;

        @media (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 0; } }



    .text-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8 * $base-width;

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            margin: 0 -4 * $base-width; }

        .col {
            padding: 3 * $base-width 8 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding: 3 * $base-width 4 * $base-width; }

            &.col-1 {
                flex: 0 0 100%; }

            &.col-2 {
                flex: 0 0 50%;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; }


                &:last-child,
                &:nth-last-child(2) {
                    padding-bottom: 0; }

                &:nth-last-child(2) {
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        padding-bottom: 3 * $base-width; } } }



            &.col-3 {
                flex: 0 0 33.333%;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; }


                &:last-child,
                &:nth-last-child(2) {
                    padding-bottom: 0; }

                &:nth-last-child(2) {
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        padding-bottom: 3 * $base-width; } } }


            &.col-4 {
                flex: 0 0 25%;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 33.333%; }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; }


                &:last-child,
                &:nth-last-child(2) {
                    padding-bottom: 0; }

                &:nth-last-child(2) {
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        padding-bottom: 3 * $base-width; } } }

            p {
                &:last-child {
                    padding: 0; } }

            ul {
                padding-left: 3 * $base-width;

                li {
                    margin-bottom: $base-width; } }

            .button {
                &.align-center {
                    text-align: center; } }

            .btn {
                margin-top: 2 * $base-width; } } } }
