.cart-page {
  padding: 18 * $base-width 16 * $base-width;

  @media (max-width: #{map-get($breakpoints, xxxl)}) {
    padding: 14 * $base-width 12 * $base-width; }

  @media (max-width: #{map-get($breakpoints, xl)}) {
    padding: 11 * $base-width 5 * $base-width; }

  @media (max-width: #{map-get($breakpoints, s)}) {
    padding: 9 * $base-width 3 * $base-width; }

  .return-to-shop {
    text-align: center; }

  .h1-light {
    position: relative;
    line-height: 100%; }


  &.first {
    background-color: $bg-color; } }

.woocommerce {
  table.shop_table {
    background-color: $highlight2;
    padding: 3 * $base-width;
    border-radius: 0;

    &.shop_table {
      border: 0;

      td, th {
        padding: 2 * $base-width 0;

        @media (max-width: #{map-get($breakpoints, m)}) {
          text-align: left !important;

          &::before {
            float: unset; } } }

      td.product-thumbnail {
        padding-right: 3 * $base-width; } }


    .product-thumbnail {
      a {
        width: 250px;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover; } } }

    .product-quantity {
      button {
        padding: 2px; }

      input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        border: 0;
        background: none;
        color: $dark;
        font-size: 1.8rem;
        padding: 2px 4px;

        &:focus-visible {
          outline: none; } } }

    .coupon {
      input.input-text {
        width: 250px;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100% !important; }

        &:focus-visible {
          outline: 0; } }
      button {
        border-radius: 0;
        background: none;
        background-color: $primary;
        color: $light;
        padding: 2 * $base-width 3 * $base-width; }

      button.button.wp-element-button {
        background-color: $secondary;
        padding: 2 * $base-width 4 * $base-width;
        color: $light;
        position: relative;
        overflow: hidden;
        background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
        border-radius: 0;
        background-size: 200%;
        background-position: right;
        color: $light;
        transition: $transition;
        font-weight: 400;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-position: left;
            transition: $transition; } }

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100% !important; } } } }

  button {
    border-radius: 0;
    background: none;
    background-color: $primary;
    color: $light;

    &.btn-link {
      border-radius: 0;
      background: none;
      color: $secondary; }

    &:disabled, &[disabled] {
      opacity: 0.6;
      cursor: no-drop; } }

  .cart-collaterals {
    margin-top: 8 * $base-width;

    .cart_totals {
      width: 100%;
      float: unset;

      .wc-proceed-to-checkout {
        text-align: right; } } } }
