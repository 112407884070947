

/* outfit-300 - light - latin */
@font-face {
  font-display: swap;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/outfit-v6-latin-300.woff2') format('woff2'),
  url('../../fonts/outfit-v6-latin-300.woff') format('woff') {} }

/* outfit-600 Semibold - latin */
@font-face {
  font-display: swap;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/outfit-v6-latin-600.woff2') format('woff2'),
  url('../../fonts/outfit-v6-latin-600.woff') format('woff') {} }

/* Dulan */
@font-face {
  font-family: 'dulan_anzelicaregular';
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/dulan_anzelica-webfont.woff2') format('woff2'),
  url('../../fonts/dulan_anzelica-webfont.woff') format('woff') {} }
