
.woocommerce {
  .cats {
    background-color: $bg-color;
    padding-bottom: 8 * $base-width;
    padding-top: 8 * $base-width;

    @media (max-width: #{map-get($breakpoints,s)}) {
      padding-bottom: 3 * $base-width;
      padding-top: 1 * $base-width;
      padding-left: 3 * $base-width;
      padding-right: 3 * $base-width; }

    .cat-toggle {
      display: none;
      width: 100%;
      border: 0;
      color: $dark;
      background-color: #E1F1EA;
      height: 55px;
      border-radius: 0;
      padding: 2 * $base-width;
      font-family: $font-default;
      font-size: 1.8rem;
      cursor: pointer;
      margin-bottom: 2 * $base-width;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        right: 2* $base-width;
        top: 3 * $base-width;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z' fill='%236CB994'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.4rem;
        width: 1.4rem;
        height: 1.4rem;
        transform: rotate(0deg);
        transition: $transition; }


      &.open {
        &:before {
          transform: rotate(-180deg);
          transition: $transition; } }


      @media (max-width: #{map-get($breakpoints,s)}) {
        display: block; } }


    .cat-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      flex-wrap: wrap;

      @media (max-width: #{map-get($breakpoints,s)}) {
        flex-direction: column;
        display: none;

        &.open {
          display: flex; } }

      .main-cats {

        a.link {
          padding: $base-width 2 * $base-width;
          font-weight: 600;
          font-size: 2rem;

          @media (max-width: #{map-get($breakpoints,s)}) {
            font-size: 1.8rem; } }

        .sub-cats {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;

          @media (max-width: #{map-get($breakpoints,s)}) {
            position: relative;
            top: unset; }

          .sublink {
            padding: $base-width 2 * $base-width;

            &.active {
              color: $primary; } }

          &.active {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @media (max-width: #{map-get($breakpoints,s)}) {
              flex-direction: column; } } }


        &.active {
          a.link {
            color: $primary; }

          .sub-cats {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @media (max-width: #{map-get($breakpoints,s)}) {
              flex-direction: column;
              margin-left: 2 * $base-width; } } } } } }


  .cat-headimage {
    position: relative;
    padding: 0 16 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 0 12 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 0 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 0 3 * $base-width; }

    &:after {
      content: "";
      background-color: $bg-color;
      height: 450px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: -1; }

    .wrapper {
      position: relative;

      picture {
        display: block;
        padding-top: 40%;
        position: relative;
        margin: 0 auto;

        @media (max-width: #{map-get($breakpoints, s)}) {
          padding-top: 56.25%; }

        img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-color: $light; }

        &.no-crop {
          border: 1px solid $highlight2;
          img {
            object-fit: contain; } } }


      .cat-name {
        position: absolute;
        left: 1.5rem;
        right: 0;
        bottom: 1.5rem;
        z-index: 1;
        color: $light;
        margin-bottom: 0;
        font-family: $font-heading;
        font-size: 12.5rem;
        line-height: 90%;
        font-weight: normal;
        text-shadow: 0 3px 6px rgba(#000, 0.16);

        @media (max-width: #{map-get($breakpoints, xxl)}) {
            font-size: 8rem; }

        @media (max-width: #{map-get($breakpoints, s)}) {
            font-size: 4rem;
            line-height: 100%; } } } }


  .result-count-ordering {
    padding: 14 * $base-width 16 * $base-width 4 * $base-width;
    background-color: $bg-color;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 12 * $base-width 12 * $base-width 4 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 9 * $base-width 5 * $base-width 4 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 9 * $base-width 3 * $base-width 3 * $base-width 3 * $base-width; }

    .category-description {
      padding-bottom: 14 * $base-width;
      text-align: center;
      max-width: $small;
      margin: 0 auto;

      @media (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 8 * $base-width; } }

    .inner {
      display: flex;
      justify-content: space-between;

      @media (max-width: #{map-get($breakpoints, s)}) {
        flex-wrap: wrap; }

      .woocommerce-result-count, .woocommerce-ordering {
        float: unset;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%;
          margin-bottom: 0; }

        select {
          width: 100%;
          border: 0;
          color: $dark;
          background-color: #E1F1EA;
          height: 55px;
          border-radius: 0;
          padding: 2 * $base-width;
          font-family: $font-default;
          font-size: 1.8rem;
          cursor: pointer;
          margin-bottom: 2 * $base-width;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z' fill='%236CB994'/%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.7em top 50%, 0;
          background-size: 1.4rem auto;
          padding-right: 2.5em;
          margin-bottom: 0;


          &:focus-visible {
            outline: none; }

          option {
            font-size: 16px;
            background-color: $light;
            color: $dark;
            border-radius: 0;
            padding-top: 5px;
            padding-bottom: 5px;

            &:hover {
              background-color: #E1F1EA; } } } } } }

  .products-list-teaser {
    position: relative;
    padding: 0 16 * $base-width 18 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 0 12 * $base-width 14 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 0 5 * $base-width 11 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 0 3 * $base-width 9 * $base-width; }

    &:after {
      content: "";
      background-color: $bg-color;
      height: 450px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1; } }

  nav.woocommerce-pagination {
    margin-bottom: 8 * $base-width;
    ul {
      border: 0;

      li {
        border: 0;
        margin: $base-width;

        span, a {
          background-color: $bg-color;
          width: 60px;
          height: 60px;
          line-height: 40px;
          color: $primary;

          &.current {
            background-color: $highlight2;
            color: $primary; } }

        a {
          &:hover {
            background-color: $primary;
            color: $light; } } } } } }


main {
  > .term-description {
    display: none; } }
