.woocommerce {
  div.product {
    .woocommerce-breadcrumb {
      margin-bottom: 4 * $base-width;

      a {
        &:hover {
          color: $secondary; } } }

    .summary.entry-summary {
      position: relative;

      &:before {
        content: "";
        background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_2.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 290px;
        width: 290px;
        position: absolute;
        left: 100%;
        bottom: -140px;
        transform: rotate(-15deg);
        z-index: 1;
        animation: floatingVideo 6s infinite linear;

        @-webkit-keyframes floatingVideo {
          0% {
            transform: translateY(0px) rotate(-15deg); }

          50% {
            transform: translateY(25px) rotate(-15deg); }

          100% {
            transform: translateY(0px) rotate(-15deg); } }

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
          height: 220px;
          width: 220px;
          bottom: -190px;
          left: 90%; }

        @media (max-width: #{map-get($breakpoints, xl)}) {
          display: none; } }

      .onsale {
        right: 0;
        left: unset;
        top: -42px;
        height: 42px;
        width: 42px;
        background-color: $sale;
        padding: 0;
        font-size: 2rem; }

      .dscr {
        margin-bottom: 6 * $base-width; }

      .price {
        color: $dark;
        font-weight: 700;
        font-size: 3.2rem;

        .woocommerce-var-price {
          .woocommerce-Price-amount {
            bdi {
              color: $sale;
              text-decoration: none;
              font-weight: 700; } }

          del {
            font-size: 2.2rem;

            .woocommerce-Price-amount {
              font-size: 2.2rem;

              bdi {
                color: $dark;
                font-size: 2.2rem; } } } } }


      .legal-price-info {
        .wc-gzd-additional-info {
          padding-bottom: 0; } }


      .variations {
        .reset_variations {
          display: none; }

        tr {
          .label {
            display: none; }

          .value {

            select {
              width: 100%;
              border: 0;
              color: $dark;
              background-color: #E1F1EA;
              height: 55px;
              border-radius: 0;
              padding: 2 * $base-width;
              font-family: $font-default;
              font-size: 1.8rem;
              margin-bottom: 2 * $base-width;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z' fill='%236CB994'/%3E%3C/g%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: right 0.7em top 50%, 0;
              background-size: 1.4rem auto;
              padding-right: 2.5em;
              cursor: pointer;

              &:focus-visible {
                outline: none; }

              option {
                font-size: 16px;
                background-color: $light;
                color: $dark;
                border-radius: 0;
                padding-top: 5px;
                padding-bottom: 5px;

                &:hover {
                  background-color: #E1F1EA; } } }

            .reset_variations {
              display: none; } } } }

      .single_variation_wrap {
        .woocommerce-variation-add-to-cart {
          display: flex;
          justify-content: space-between;

          .card-button {
            flex: 0 0 85%;
            width: 85%;
            color: $light;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            font-size: 1.8rem;
            height: 60px;

            @media (max-width: #{map-get($breakpoints, l)}) {
              flex: 0 0 75%;
              width: 75%; }

            svg {
              width: 27px;
              height: 23px;
              fill: $light;
              position: relative;
              z-index: 2;
              margin-right: 8px; } }

          // .quantity
          //   display: none

          .quantity {
            flex: 0 0 13%;
            width: 13%;
            display: flex;
            background-color: #E1F1EA;

            @media (max-width: #{map-get($breakpoints, l)}) {
              flex: 0 0 25%;
              width: 25%; }

            input {
              width: 50%;
              text-align: center;
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: textfield;
              border: 0;
              background-color: #E1F1EA;
              color: $dark;
              font-size: 1.8rem;
              height: 60px;

              &:focus-visible {
                outline: none; } }

            button {
              width: 25%;
              background-color: $primary;
              color: $light;
              transition: $transition;

              &:hover {
                background-color: $secondary;
                transition: $transition; } } } } }

      .single_add_to_cart_button {
        flex: 0 0 85%;
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 1.8rem;
        height: 60px;
        background-color: $secondary;
        padding: 2 * $base-width 4 * $base-width;
        position: relative;
        overflow: hidden;
        background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
        border-radius: 0;
        background-size: 200%;
        background-position: right;
        color: $light;
        transition: $transition;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: $font-default;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-position: left; } }

        @media (max-width: #{map-get($breakpoints, l)}) {
          flex: 0 0 75%;
          width: 75%; }

        svg {
          width: 27px;
          height: 23px;
          fill: $light;
          position: relative;
          z-index: 2;
          margin-right: 8px; } }


      .quantity {
        flex: 0 0 13%;
        width: 13%;
        display: flex;
        background-color: #E1F1EA;

        @media (max-width: #{map-get($breakpoints, l)}) {
          flex: 0 0 25%;
          width: 25%; }

        input {
          width: 50%;
          text-align: center;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: textfield;
          border: 0;
          background-color: #E1F1EA;
          color: $dark;
          font-size: 1.8rem;
          height: 60px;

          &:focus-visible {
            outline: none; } }

        button {
          width: 25%;
          background-color: $primary;
          color: $light;
          transition: $transition;

          &:hover {
            background-color: $secondary;
            transition: $transition; } } } }

    .cwginstock-subscribe-form {
      background-color: $highlight2;
      padding: 2rem;

      .panel-primary {
        border: 0;
        margin: 0;
        box-shadow: none;

        > .panel-heading {
          background: none;
          border: 0;
          padding: 0;

          h4 {
            font-size: 2rem;
            margin-bottom: 2rem;
            text-transform: none;
            letter-spacing: 0;
            color: $secondary; } } }

      .cwginstock-panel-body {
        padding: 0;

        input[type=email] {
          margin-bottom: 0; }

        .cwg_iagree_checkbox {
          color: $dark;

          a {
            color: $dark; } }

        .cwgstock_button {
          border: 0;
          padding: 1.5rem 2rem;
          background-color: $secondary;
          font-size: 1.6rem;
          font-family: $font-default;
          cursor: pointer;
          transition: $transition;

          &:hover {
            background-color: $primary;
            transition: $transition; } } }

      .cwgstock_output {
        font-size: 1.5rem;
        line-height: 2rem; } } } }
