.block-video {
    &.bg-green {
        background-color: $bg-color; }

    &.feder {
        &:before {
            content: "";
            background-image: url('/wp-content/themes/bergauf/assets/img/graphic/feder_2.svg');
            background-repeat: no-repeat;
            background-size: contain;
            height: 390px;
            width: 390px;
            position: absolute;
            right: 0%;
            top: -240px;
            transform: rotate(-15deg);
            z-index: 1;
            animation: floatingVideo 6s infinite linear;

            @-webkit-keyframes floatingVideo {
                0% {
                    transform: translateY(0px) rotate(-15deg); }

                50% {
                    transform: translateY(25px) rotate(-15deg); }

                100% {
                    transform: translateY(0px) rotate(-15deg); } }

            @media (max-width: #{map-get($breakpoints, xxl)}) {
                height: 290px;
                width: 290px;
                top: -190px; }

            @media (max-width: #{map-get($breakpoints, l)}) {
                height: 200px;
                width: 200px;
                top: -120px; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                height: 160px;
                width: 160px;
                top: -120px; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                height: 110px;
                width: 110px;
                top: -100px; } } }

    .wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &.img-right {
            flex-direction: row-reverse;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex-wrap: wrap-reverse; } }

        &.img-left {
            flex-direction: row;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex-wrap: wrap-reverse; } } }

    .text {
        align-content: center;
        align-items: center;
        flex: 0 0 48%;
        width: 48%;
        padding: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            min-height: unset;
            margin-bottom: 8 * $base-width;
            flex: 0 0 100%;
            width: 100%; }

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            margin-bottom: 4 * $base-width; }

        &.img-right {
            padding-right: 8 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-right: 0; } }

        &.img-left {
            padding-left: 8 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-left: 0; } } }

    .media {
        z-index: 2;
        min-height: 100%;
        flex: 0 0 52%;
        width: 52%;
        padding-top: calc(56.25% / 2);
        position: relative;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 56.25%;
            flex: 0 0 100%;
            width: 100%; }

        .BorlabsCookie {
            z-index: 9;
            ._brlbs-content-blocker {
                height: 100%;

                ._brlbs-embed {
                    height: 100%;
                    width: 100%;

                    ._brlbs-caption {
                        z-index: 9; } } } }

        img, iframe, video, video[poster], .BorlabsCookie {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; }

        .video-poster, .video-poster img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            z-index: 1;
            cursor: pointer; }

        .play-button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 80px;
            height: 80px;
            margin: auto;
            background-color: $light;
            border-radius: 100%;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent $primary;
                display: inline-block; } } } }

.block-video.bg-green + .block-video.bg-green {
    padding-top: 0; }
