.woocommerce {
  div.product {
    .product-entry {
      background-color: $bg-color;

      padding: 18 * $base-width 16 * $base-width;

      @media (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 14 * $base-width 12 * $base-width; }

      @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 11 * $base-width 5 * $base-width; }

      @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 9 * $base-width 3 * $base-width; } }


    div.images {
      .woocommerce-product-gallery__trigger {
        background-color: $primary;

        &:after {
          background-color: $light; }

        &:before {
          border-color: $light; } }

      .woocommerce-product-gallery__wrapper {
        a {
          width: 100%;

          img {
            height: 650px;
            width: 100%;
            object-fit: cover;
            cursor: default;

            @media (max-width: #{map-get($breakpoints, l)}) {
              height: 500px; }

            @media (max-width: #{map-get($breakpoints, s)}) {
              height: 300px; } } }

        &.crop {
          a {
            img {
              background-color: $light;
              object-fit: contain; } } } }

      .flex-control-thumbs {
        margin: 0 -5px;

        li {
          width: 16.666%;
          padding: 5px;

          @media (max-width: #{map-get($breakpoints, l)}) {
            width: 25%; } } } } } }


.pswp__bg {
  background: rgba($dark, 0.8); }
