// Base
$base-width: .8rem;

// Fonts
$font-default: 'Outfit', sans-serif;
$font-heading: 'dulan_anzelicaregular';

// General
$dark: #686868;
$light: #fff;

// Brand Colors
$primary: #6CB994;
$secondary: #587FBD;

$sale: #D9A0A0;

// $highlight: #C4E3D4
$highlight: #A6D5BF;
$highlight2: #E1F1EA;
$bg-color: #F6FBF9;

// Others
$divider: rgba($dark, .3);

// Widths
$max: 2560px;
$full: 1920px;
$boxed: 1440px;
$small: 1120px;

// Transitions
$transition: .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1400px, "xxxl": 1680px, "xxxxl": 1920px);
