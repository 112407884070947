a {
    color: $dark;
    text-decoration: none;
    display: inline-block;
    transition: $transition;

    &:hover {
        color: $secondary; }

    &:focus {
        outline: 0; } }

strong,
.bold {
    font-weight: 600; }

p {
    padding-bottom: 2 * $base-width; }

.block {
    ul {
        list-style: none;
        padding-left: 15px;

        li {
            position: relative;
            margin-bottom: $base-width;

            &:before {
                content: " ";
                background-color: $primary;
                width: 8px;
                height: 2px;
                position: absolute;
                left: -15px;
                top: 14px; } } } }

button {
    &:focus {
        outline: none; } }

.h1-light {
    font-family: $font-heading;
    font-size: 17rem;
    line-height: 150%;
    font-weight: normal;
    color: $highlight;
    position: absolute;
    z-index: 2;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 16rem; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 12rem;
        line-height: 120%; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 9rem; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        font-size: 6rem; } }

.h2-light {
    font-family: $font-heading;
    font-size: 12.5rem;
    line-height: 140%;
    font-weight: normal;
    color: $highlight;
    position: absolute;
    z-index: 2;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 10rem; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 8rem; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 7rem; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        font-size: 5rem; } }

.h3-light {
    font-family: $font-heading;
    font-size: 10rem;
    line-height: 140%;
    font-weight: normal;
    color: $highlight;
    position: absolute;
    z-index: 2;

    @media (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 7rem; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 6rem; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        font-size: 5rem; } }


.h4-light {
    font-family: $font-heading;
    font-size: 6rem;
    line-height: 150%;
    font-weight: normal;
    color: $highlight;
    z-index: 2; }

h1,
.h1 {
    font-family: $font-default;
    font-size: 4.8rem;
    line-height: 130%;
    display: block;
    font-weight: 400;
    color: $secondary;
    margin-bottom: 2 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 4.4rem; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 4rem; }

    @media (max-width: #{map-get($breakpoints, sm)}) {
        font-size: 3.6rem; } }



h2,
.h2 {
    font-family: $font-default;
    font-size: 4rem;
    line-height: 110%;
    display: block;
    font-weight: 400;
    color: $secondary;
    margin-bottom: 3 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 3.6rem; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 3.2rem; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        font-size: 3rem; } }

h3,
.h3 {
    font-family: $font-default;
    font-size: 3.2rem;
    line-height: 120%;
    display: block;
    font-weight: 400;
    color: $secondary;
    margin-bottom: 3 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 2.8rem; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2.4rem; } }

h4,
.h4 {
    font-family: $font-default;
    font-size: 2.2rem;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: 1px;
    display: block;
    font-weight: 400;
    color: $secondary;
    margin-bottom: 3 * $base-width;

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2rem; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        font-size: 1.8rem; } }
