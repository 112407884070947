@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';

/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/button';
@import './layout/search';

/* Blocks */
@import '../../blocks/hero-image/style';
@import '../../blocks/text-bild/style';
@import '../../blocks/text-bild-teaser/style';
@import '../../blocks/teaser/style';
@import '../../blocks/gallery/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/product-teaser/style';
@import '../../blocks/logoleiste/style';
@import '../../blocks/form/style';
@import '../../blocks/video/style';


/* Woocommerce */
@import './woocommerce/single/summary';
@import './woocommerce/single/gallery';
@import './woocommerce/single/tabs';
@import './woocommerce/list-teaser';
@import './woocommerce/shop-page';
@import './woocommerce/cart';
@import './woocommerce/notices';
@import './woocommerce/checkout';
@import './woocommerce/thankyou';
@import './woocommerce/account/login';
@import './woocommerce/account/lost-password';
@import './woocommerce/account/account';
@import './woocommerce/retoure';
