.woocommerce-form-return-request {

  .form-row {
    margin-top: 4 * $base-width;
    input {
      width: 100%;
      height: 6 * $base-width;
      padding: 2 * $base-width;
      border: 0;
      border: 1px solid $primary;

      &:focus-visible {
        outline: 0; } }

    button.woocommerce-form-return_request__submit {
      background-color: $secondary;
      padding: 2 * $base-width 4 * $base-width;
      color: $light;
      overflow: hidden;
      background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
      border-radius: 0;
      background-size: 200%;
      background-position: right;
      color: $light;
      transition: $transition;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-position: left;
          transition: $transition; } } } } }

.woocommerce {
  .shipping-retoure {
    background-color: $bg-color;

    table {
      background-color: $highlight2;

      + p {
        max-width: $boxed;
        margin: 0 auto;

        button.wp-element-button {
          background-color: $secondary;
          padding: 2 * $base-width 4 * $base-width;
          color: $light;
          position: relative;
          overflow: hidden;
          background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
          border-radius: 0;
          background-size: 200%;
          background-position: right;
          color: $light;
          font-weight: 400;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-position: left; } } } }

      thead {
        @media (max-width: #{map-get($breakpoints, m)}) {
          display: none; } }

      tbody {
        .return_shipment_item {
          @media (max-width: #{map-get($breakpoints, m)}) {
            display: flex;
            flex-wrap: wrap; }

          td {
            @media (max-width: #{map-get($breakpoints, m)}) {
              display: flex; }

            &.woocommerce-table__product-select {
              @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%;

                input {
                  margin-right: $base-width; } } }

            &.woocommerce-table__product-return-reason {
              @media (max-width: #{map-get($breakpoints, m)}) {
                select {
                  width: 100%; } } }

            &.woocommerce-table__product-quantity {
               @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; } } } } } } } }
