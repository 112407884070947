.login-page {
  padding: 18 * $base-width 16 * $base-width;

  @media (max-width: #{map-get($breakpoints, xxxl)}) {
    padding: 14 * $base-width 12 * $base-width; }

  @media (max-width: #{map-get($breakpoints, xl)}) {
    padding: 11 * $base-width 5 * $base-width; }

  @media (max-width: #{map-get($breakpoints, s)}) {
    padding: 9 * $base-width 3 * $base-width; }

  #customer_login {
    .u-column1,.u-column2 {
      input[type="text"],input[type="email"],input[type="password"] {
        padding: $base-width 2 * $base-width;
        height: 40px;
        border: 1px solid $primary; }

      .show-password-input {
        top: 7px; }

      .woocommerce-form {
        .btn {
          width: 100%; } }

      .lost_password {
        margin-top: 2 * $base-width; } }


    .u-column2 {
      .woocommerce-form.woocommerce-form-register {
        background-color: $bg-color;
        border: 0;
        border-radius: 0;
        border: 1px solid $highlight2; } }

    .u-column1 {
      .woocommerce-form.woocommerce-form-login {
        border: 0;
        border-radius: 0;
        border: 1px solid $highlight2; } } } }
