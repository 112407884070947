
.block-checkout {
  background-color: $bg-color;

  padding: 18 * $base-width 16 * $base-width;

  @media (max-width: #{map-get($breakpoints, xxxl)}) {
    padding: 14 * $base-width 12 * $base-width; }

  @media (max-width: #{map-get($breakpoints, xl)}) {
    padding: 11 * $base-width 5 * $base-width; }

  @media (max-width: #{map-get($breakpoints, s)}) {
    padding: 9 * $base-width 3 * $base-width; }

  form.checkout {
    input[type="text"],input[type="tel"],input[type="email"], select#billing_country, select#shipping_address_type {
      height: 50px;
      padding: $base-width 2 * $base-width;
      border: 1px solid $primary;
      border-radius: 0;
      font-size: 1.8rem;
      color: $dark;
      font-family: $font-default; }

    input[type="checkbox"] {
      min-width: 15px;
      min-height: 15px; }

    .select2-container--default {
      .select2-selection--single {
        height: 50px;
        padding: 0 2 * $base-width;
        border: 1px solid $primary;
        border-radius: 0;


        .select2-selection__rendered {
          line-height: 50px;
          padding: 0; }
        .select2-selection__arrow {
          top: 10px; } } } }

  textarea {
    font-family: $font-default;
    appearance: none;
    -webkit-appearance: none;
    font-size: 1.8rem;
    padding: 2 * $base-width;
    border: 1px solid $primary;
    border-radius: 0; }


  #order_review {
    padding-top: 8 * $base-width;

    #payment {
      background: none;
      li {
        &:before {
          display: none; }

        input {
          height: 15px; } }

      ul.payment_methods {
        border: 0;
        padding: 0;

        li {
          input {
            margin-right: 5px; } } }

      div.payment_box {
        background-color: #E1F1EA;

        &:before {
          display: none; }

        p {
          padding-bottom: 0; } } }

    .wc-gzd-checkbox-placeholder.wc-gzd-checkbox-placeholder-legal {
      margin: 5 * $base-width 0;
      padding: 2 * $base-width 0;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;

      span {
        a {
          color: $secondary; } } }

    .dhl-preferred-service-content {
      margin-top: 6 * $base-width;
      margin-bottom: 6 * $base-width; } }

  .woocommerce-shipping-fields {
    #ship-to-different-address {
      font-size: 2rem;

      @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 1.6rem;
        color: $dark;
        padding: 3px;

        span {
          margin-left: 0.8rem; } } } }



  .woocommerce-checkout {
    .shop_table {
      background: 0;

      .cart_item {
        .product-name {
          img {
            display: none; } } }

      &.woocommerce-checkout-review-order-table {
        background-color: $highlight2;
        border-radius: 0;

        #shipping_method {
          li {
            display: flex;
            align-items: center;

            input {
              margin-top: 0;
              // accent-color: $primary
              min-width: 14px;
              width: 14px;
              height: 14px; } } } } } }



  .wc-gzd-order-submit {
    button.button.alt {
      background-color: $primary;
      background: linear-gradient(90deg, rgba(88,127,189,1) 0%, rgba(88,127,189,1) 50%, rgba(108,185,148,1) 50%, rgba(108,185,148,1) 100%);
      border-radius: 0;
      background-size: 200%;
      background-position: right;
      width: 100%;
      height: 70px;
      transition: $transition;

      &:hover {
        transition: $transition;
        background-position: left; } } } }

.woocommerce-form {
  &.woocommerce-form-login {
    &.login {
      max-width: $boxed;
      margin: 0 auto;

      .form-row {
        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%; } }


      .woocommerce-button.button.woocommerce-form-login__submit.wp-element-button {
        background-color: $secondary;
        padding: 2 * $base-width 4 * $base-width;
        color: $light;
        position: relative;
        overflow: hidden;
        background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
        border-radius: 0;
        background-size: 200%;
        background-position: right;
        transition: $transition;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 100%; }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-position: left;
            transition: $transition; } } } } } }


.woocommerce-cart-form__contents {
  .button {
    background-color: $primary !important;
    color: $light !important;
    border-radius: 0 !important;
    padding: 2 * $base-width 3 * $base-width !important; } }


.checkout_coupon.woocommerce-form-coupon {
  max-width: $boxed;
  margin: 0 auto !important;

  .form-row {
    @media (max-width: #{map-get($breakpoints, s)}) {
      width: 100%; } }

  .button.wp-element-button {
    background-color: $secondary;
    padding: 2 * $base-width 4 * $base-width;
    color: $light;
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, rgba($secondary,1) 0%, rgba($secondary,1) 50%, rgba($primary,1) 50%, rgba($primary,1) 100%);
    border-radius: 0;
    background-size: 200%;
    background-position: right;
    transition: $transition;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-position: left;
        transition: $transition; } }

    @media (max-width: #{map-get($breakpoints, s)}) {
      width: 100%; } } }
