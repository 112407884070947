* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll;

    &.hidden {
        overflow: hidden; } }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.8rem;
    color: $dark;
    line-height: 160%;
    font-weight: 300;
    font-family: $font-default;
    overflow-x: hidden;
    max-width: $max;
    background-color: $light;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 25px rgba(#000, 0.08);

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 1.6rem; }

    &.hidden {
        overflow: hidden;
        height: 100%;
        block-size: 100%;

        &:after {
            content: " ";
            background-color: rgba(#000000, 0.66);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 4;
            transition: $transition;
            right: 0;
            left: 0; } } }

img {
    display: block;
    max-width: 100%; }

main,
.block {
    position: relative; }

main {
    padding-top: 116px;
    // padding-top: 200px

    @media (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 95px;
 } }        // padding-top: 235px

.block {
    padding: 18 * $base-width 16 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 14 * $base-width 12 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 11 * $base-width 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 9 * $base-width 3 * $base-width; }

    &.more-padding {
        padding-top: 26 * $base-width;

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
            padding-top: 20 * $base-width; }

        @media (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 16 * $base-width; }

        @media (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 10 * $base-width; }

        @media (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 8 * $base-width; }

        .h1-light {
            left: 4 * $base-width;
            top: -32 * $base-width;

            @media (max-width: #{map-get($breakpoints, xxxl)}) {
                top: -24 * $base-width; }

            @media (max-width: #{map-get($breakpoints, xl)}) {
                top: -16 * $base-width;
                left: 0; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                top: -14 * $base-width; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                top: -10 * $base-width; } } }


    &.more-padding-2 {
        padding-top: 20 * $base-width;

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
            padding-top: 16 * $base-width; }

        @media (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 14 * $base-width; }

        @media (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 12 * $base-width; }

        @media (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 8 * $base-width; }

        .h2-light {
            left: 4 * $base-width;
            top: -22 * $base-width;

            @media (max-width: #{map-get($breakpoints, xxxl)}) {
                top: -18 * $base-width; }

            @media (max-width: #{map-get($breakpoints, xl)}) {
                top: -16 * $base-width;
                left: 0; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                top: -14 * $base-width; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                top: -10 * $base-width; } } }


    &.more-padding-3 {
        padding-top: 22 * $base-width;

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
            padding-top: 16 * $base-width; }

        @media (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 12 * $base-width;
            left: 0; }

        @media (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 10 * $base-width; }

        @media (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 8 * $base-width; }

        .h3-light {
            left: -4 * $base-width;
            top: -24 * $base-width;

            @media (max-width: #{map-get($breakpoints, xxxl)}) {
                top: -18 * $base-width; }

            @media (max-width: #{map-get($breakpoints, xl)}) {
                top: -14 * $base-width;
                left: 0; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                top: -12 * $base-width; }

            @media (max-width: #{map-get($breakpoints, s)}) {
                top: -10 * $base-width; } } } }

.wrapper {
    max-width: $boxed;
    margin: 0 auto; }

.wrapper-full {
    max-width: $full;
    margin: 0 auto;
    position: relative; }

.headline-wrapper.align-center {
    text-align: center; }


a:not(.btn) {
    @media (max-width: #{map-get($breakpoints, s)}) {
        overflow-wrap: break-word; } }
