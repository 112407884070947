footer {
  position: relative;

  .usps {
    background-color: $bg-color;
    padding: $base-width 16 * $base-width;
    margin: 0 -$base-width;
    border-top: 2px solid $light;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: $base-width 12 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: $base-width 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: $base-width 3 * $base-width; }

    .wrapper {
      .items {
        display: flex;
        justify-content: space-between;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          flex-wrap: wrap;
          justify-content: center; }

        @media (max-width: #{map-get($breakpoints, s)}) {
          justify-content: flex-start; }

        .item {
          padding: 2 * $base-width $base-width;
          display: flex;
          align-items: center;

          @media (max-width: #{map-get($breakpoints, l)}) {
            padding: $base-width; }

          svg {
            height: 28px;
            width: 33px;
            fill: $primary;
            margin-right: $base-width; } } } } }

  .inner {
    padding: 0 16 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 0 12 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 0 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 0 3 * $base-width; }

    .row {
      display: flex;

      .col {
        padding-right: 5 * $base-width;

        .hours {
          .wrap {
            display: flex;
            margin-bottom: $base-width;

            .day {
              width: 25%; }

            .time {
              width: 75%; } }

          + p {
            padding-bottom: 0; } }

        .payment, .shipping {
          display: flex;

          .item {
            border: 1px solid $highlight;
            border-radius: 8px;
            padding: 3px 6px;
            width: 95px;
            margin-right: $base-width;
            margin-bottom: $base-width; } }

        ul {
          list-style: none;

          li {
            margin-bottom: $base-width;

            &.active {
              a {
                color: $secondary; } } } } }


      &.row-1 {
        align-items: center;
        justify-content: space-between;
        padding-top: 6 * $base-width;
        padding-bottom: 6 * $base-width;

        @media (max-width: #{map-get($breakpoints, s)}) {
          flex-wrap: wrap;
          justify-content: flex-start; }

        .col {
          padding: 0;

          @media (max-width: #{map-get($breakpoints, s)}) {
            flex: 0 0 100%; } }

        .h3 {
          margin-bottom: 0;

          @media (max-width: #{map-get($breakpoints, s)}) {
            margin-bottom: 3 * $base-width; } }

        ul {
          display: flex;

          @media (max-width: #{map-get($breakpoints, s)}) {
            flex-direction: column; }

          li {
            padding: $base-width 0 $base-width 4 * $base-width;
            margin: 0;

            @media (max-width: #{map-get($breakpoints, s)}) {
              padding: 0;
              margin-bottom: $base-width; } } } }

      &.row-2 {
        justify-content: space-between;
        padding-top: 12 * $base-width;
        padding-bottom: 12 * $base-width;
        border-top: 1px solid $highlight;
        border-bottom: 1px solid $highlight;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          flex-wrap: wrap; }

        @media (max-width: #{map-get($breakpoints, s)}) {
          padding-top: 6 * $base-width;
          padding-bottom: 6 * $base-width; }

        .col {
          @media (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 50%;

            &:first-child, &:nth-child(2) {
              padding-bottom: 6 * $base-width; } }

          @media (max-width: #{map-get($breakpoints, s)}) {
            flex: 0 0 100%;
            padding-right: 0;

            &:nth-child(3) {
              padding-bottom: 6 * $base-width; } } } }

      &.row-3 {
        justify-content: space-between;
        padding-top: 3 * $base-width;
        padding-bottom: 3 * $base-width;

        @media (max-width: #{map-get($breakpoints, s)}) {
          justify-content: flex-start;
          flex-wrap: wrap; } } } } }
