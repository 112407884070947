header#header {
  padding: 0 16 * $base-width;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: $light;
  max-width: $max;
  margin: 0 auto;

  @media (max-width: #{map-get($breakpoints, xxxl)}) {
    padding: 0  12 * $base-width; }

  @media (max-width: #{map-get($breakpoints, xl)}) {
    padding: 0  5 * $base-width; }

  @media (max-width: #{map-get($breakpoints, s)}) {
    padding: 0 3 * $base-width; }

  &.search-open {
    @media (max-width: #{map-get($breakpoints, xxl)}) {
      &:before {
        content: " ";
        background-color: rgba($dark, 0.66);
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 100%;
        bottom: 0;
        z-index: 1;
        transition: $transition;
        right: 0;
        left: 0;
        z-index: -1; } } }

  &.open {
    nav {
      top: 100%;
      transition: $transition; } }

  .wrapper {
    padding: $base-width 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: $light;

    .logo {
      padding-top: 100px;
      transition: $transition;

      @media (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 80px; }

      img {
        margin: 0 auto;
        width: 150px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        transition: $transition;

        @media (max-width: #{map-get($breakpoints, m)}) {
          width: 100px; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          width: 80px; } } } }

  &.fixed {
    border-bottom: 1px solid $highlight;

    nav {
      @media (max-width: #{map-get($breakpoints, m)}) {
        height: calc(100vh - 61px); } }

    .logo {
      padding-top: 45px;
      transition: $transition;

      img {
        transition: $transition;
        width: 120px;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 80px; } } } }

  .elements {
    display: flex;
    align-items: center;

    a.link {
      display: flex;
      align-items: center;
      margin-left: 3 * $base-width;
      position: relative;

      @media (max-width: #{map-get($breakpoints, m)}) {
        margin-left: 2 * $base-width; }

      &:hover {
        color: $primary; }

      span {
        @media (max-width: #{map-get($breakpoints, m)}) {
          display: none; } }

      svg {
        margin-right: $base-width;
        width: 2rem;
        height: 2.8rem;
        fill: $primary;

        @media (max-width: #{map-get($breakpoints, s)}) {
          width: 2rem;
          height: 3rem;
          margin-right: 0; } }

      &.phone {
        @media (max-width: #{map-get($breakpoints, m)}) {
          display: none; } }

      .cart-count {
        background-color: #E1F1EA;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        position: absolute;
        top: -5px;
        left: -12px;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center; } }

    .search {
      display: flex;
      align-items: center;
      padding: 0 3 * $base-width;

      @media (max-width: #{map-get($breakpoints, m)}) {
        padding: 0 2 * $base-width; }

      @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 0 1.5 * $base-width; }

      .action-search {
        display: flex;
        transition: $transition;
        cursor: pointer;
        transition: $transition;
        -webkit-tap-highlight-color: transparent;

        .open {
          display: flex; }

        .close {
          display: none;
          height: 4rem;
          width: 2rem;
          align-items: center;

          svg {
            height: 1.7rem;
            width: 1.7rem; } }

        &.hidden {
          .open {
            display: none; }

          .close {
            display: flex; } }


        svg {
          width: 2rem;
          height: 2.2rem;
          fill: $primary;
          stroke: $primary;

          path {
            transition: $transition; } }

        span {
          font-weight: 400;
          color: $dark;
          font-size: 1.8rem;
          line-height: 2rem;
          margin-left: 2 * $base-width;
          transition: $transition;

          &:hover {
            color: $primary; }

          @media (max-width: #{map-get($breakpoints, xxxl)}) {
            font-size: 1.6rem; }

          @media (max-width: #{map-get($breakpoints, m)}) {
            display: none; } } }

      #header-search {
        width: 0;
        overflow: hidden;
        visibility: hidden;
        transition: $transition;
        -webkit-tap-highlight-color: transparent;

        @media (max-width: #{map-get($breakpoints, xxl)}) {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0; }

        &.active {
          width: 24rem;
          visibility: visible;
          transition: $transition;

          @media (max-width: #{map-get($breakpoints, xxl)}) {
            width: 100%; }

          .btn {
            width: 2.2rem;
            margin-top: 0;
            border-bottom: 1px solid $dark;

            @media (max-width: #{map-get($breakpoints, xxl)}) {
              border: 0;
              width: 6rem;
              background-color: $primary;

              svg {
                fill: $light;
                stroke: $light; } } } }

        form {
          display: flex;
          -webkit-appearance: none;
          border-radius: 0;

          @media (max-width: #{map-get($breakpoints, xxl)}) {
            width: 100%;
            padding: $base-width 0; }

          input {
            border: 0;
            border-bottom: 1px solid $dark;
            height: 4rem;
            width: 220px;
            padding: $base-width 2 * $base-width;
            border-radius: 0;
            -webkit-appearance: none;

            @media (max-width: #{map-get($breakpoints, xxl)}) {
              width: 100%;
              height: 6rem;
              border: 0; }

            &::placeholder {
              font-family: $font-default;
              font-weight: 400;
              font-size: 1.8rem;
              color: $dark; }

            &:focus-visible {
              outline: none; } }

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0;
            background: none;

            svg {
              fill: $primary;
              stroke: $primary;
              width: 2rem;
              height: 2.2rem; } } } } }

    .navbar-toggler {
      align-items: center;
      border: 0;
      background: none;
      display: flex;
      z-index: 3;
      cursor: pointer;

      @media (max-width: #{map-get($breakpoints, s)}) {
        margin-right: 0; }

      .toggler {
        position: relative;
        height: 38px;
        width: 38px;

        .line {
          height: 2px;
          width: 33px;
          background-color: $primary;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;

          &.top {
            top: 8px;
            width: 33px;
            transition: $transition; }

          &.middle {
            top: 50%;
            width: 21px;
            transform: translateY(-50%);
            transition: $transition; }

          &.bottom {
            bottom: 8px;
            width: 29px;
            transition: $transition; } } }

      .text {
        color: $dark;
        font-size: 1.9rem;
        font-family: $font-default;
        margin-left: 2 * $base-width;
        transition: $transition;

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
          font-size: 1.6rem; }

        @media (max-width: #{map-get($breakpoints, l)}) {
          display: none; }

        &.open {
          display: block;

          @media (max-width: #{map-get($breakpoints, m)}) {
            display: none; } }

        &.close {
          display: none; } }

      &.open {
        .text {
          &.open {
            display: none; }

          &.close {
            display: block;

            @media (max-width: #{map-get($breakpoints, l)}) {
              display: none; } } }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .line {
              &.top, &.bottom {
                top: 50%;
                transition: $transition; } } } }

        .line {
          width: 33px;

          &.middle {
            opacity: 0; }

          &.top {
            top: 50%;
            transform: rotate(45deg);
            transition: $transition; }

          &.bottom {
            width: 33px;
            top: 50%;
            transform: rotate(-45deg);
            transition: $transition; } } }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          transition: $transition;

          .line {
            background-color: $primary;
            transition: $transition; }

          .text {
            color: $primary;
            transition: $transition; } } } } }

  nav {
    position: absolute;
    top: -150vh;
    opacity: 0;
    left: 0;
    right: 0;
    background-color: $light;
    z-index: -1;
    transition: 0.5s;
    border-top: 1px solid $highlight;
    padding: 2 * $base-width 16 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 2 * $base-width 12 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xl)}) {
      padding: 2 * $base-width 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, m)}) {
      height: calc(100vh - 115px);
      overflow-y: auto;
      padding-bottom: 8 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 2 * $base-width 3 * $base-width;
      padding-bottom: 8 * $base-width;
      height: calc(100vh - 115px);
      overflow-y: auto; }

    &.open {
      opacity: 1;
      transition: 0.5s;

      &:after {
        top: 0;
        transition: $transition; } }

    .wrapper {
      padding: 8 * $base-width 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: #{map-get($breakpoints, l)}) {
        padding: 4 * $base-width 0; }

      .inner {
        flex: 0 0 60%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          flex: 0 0 70%; }

        @media (max-width: #{map-get($breakpoints, l)}) {
          flex: 0 0 100%; }

        .menus {
          display: flex;
          flex: 0 0 100%;
          margin-bottom: 3 * $base-width;

          @media (max-width: #{map-get($breakpoints, m)}) {
            flex-wrap: wrap; }

          .col {
            flex: 0 0 33.333%;

            @media (max-width: #{map-get($breakpoints, m)}) {
              flex: 0 0 100%;
              padding-bottom: 4 * $base-width; }

            ul {
              list-style: none;

              li {
                margin-bottom: $base-width;

                &.active {
                  a {
                    color: $primary; } }

                &.children {

                  button {
                    background: none;
                    padding: 2px 8px;
                    transform: rotate(0deg);
                    transition: $transition;

                    svg {
                      width: 1.4rem;
                      height: 1.2rem;
                      fill: $dark; }

                    &:hover {
                      svg {
                        fill: $secondary; } } }

                  &.active {
                    svg {
                      fill: $primary; } }

                  .level-1 {
                    padding-left: 2 * $base-width;
                    max-height: 0;
                    overflow: hidden;
                    transition: $transition;
                    transition: max-height 0.6s ease-in;

                    li {
                      margin-bottom: 3px;

                      @media (max-width: #{map-get($breakpoints, m)}) {
                        margin-bottom: $base-width; }

                      a {
                        color: $dark;
                        font-size: 1.6rem;

                        &:hover {
                          color: $primary; } }

                      &.active {
                        a {
                          color: $primary; } } } }

                  &.show {
                    button {
                      transform: rotate(-180deg);
                      transition: $transition; }

                    .level-1 {
                      max-height: 250px;
                      transition: max-height 0.6s ease-in; } } } } } } }

        .meta {
          display: flex;
          justify-content: space-between;
          padding: 2 * $base-width 0;
          border-top: 1px solid $highlight;
          border-bottom: 1px solid $highlight;
          flex: 0 0 100%;

          @media (max-width: #{map-get($breakpoints, m)}) {
            flex-wrap: wrap; }

          ul {
            list-style: none;
            display: flex;

            @media (max-width: #{map-get($breakpoints, s)}) {
              margin-bottom: 2 * $base-width; }

            li {
              padding-right: 3 * $base-width;

              &.active {
                a {
                  color: $primary; } } } }

          .contact {
            display: flex;

            @media (max-width: #{map-get($breakpoints, s)}) {
              flex-wrap: wrap; }

            a.link {
              display: flex;
              align-items: center;
              margin-left: 3 * $base-width;

              @media (max-width: #{map-get($breakpoints, l)}) {
                margin-left: 0;
                margin-right: 3 * $base-width; }

              @media (max-width: #{map-get($breakpoints, s)}) {
                width: 100%;
                margin-top: $base-width; }

              &:hover {
                color: $primary; }

              svg {
                width: 2rem;
                height: 2rem;
                fill: $primary;
                margin-right: $base-width; } } } } }

      .teaser {
        flex: 0 0 30%;
        position: relative;
        overflow: hidden;
        height: 100%;

        @media (max-width: #{map-get($breakpoints, xl)}) {
          flex: 0 0 25%; }

        @media (max-width: #{map-get($breakpoints, l)}) {
          flex: 0 0 100%;
          margin-top: 4 * $base-width; }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            img {
              transform: scale(1.2);
              transition: $transition; }

            &:after {
              opacity: 0.4;
              transition: $transition; } } }

        &:after {
          content: " ";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgb(0,0,0);
          background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);
          opacity: 0.33;
          z-index: 1;
          transition: $transition; }


        .heading {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 6 * $base-width;
          width: 100%;
          z-index: 2;
          text-align: center;

          h4 {
            color: $light;
            margin-bottom: 0;

            &:before {
              content: attr(data-content);
              font-family: $font-heading;
              transform: translate(-45px, -20px);
              color: $light;
              opacity: 0.40;
              text-transform: none;
              font-size: 6rem;
              position: absolute;
              bottom: -$base-width;
              letter-spacing: 0;

              @media (max-width: #{map-get($breakpoints, xl)}) {
                font-size: 4rem; } } } }

        picture {
          padding-top: 100%;
          display: block;

          @media (max-width: #{map-get($breakpoints, l)}) {
            padding-top: 25%; }

          @media (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 56.25%; }

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            transition: $transition; } } } } } }


.head-banner {
  position: fixed;
  padding: $base-width 2 * $base-width;
  left: 0;
  top: 11.6rem;
  right: 0;
  background-color: $primary;
  color: $light;
  text-align: center;
  z-index: 10;
  font-size: 1.6rem;
  line-height: 2.2rem;
  transition: $transition;

  @media (max-width: #{map-get($breakpoints, s)}) {
    top: 9.5rem; }

  &.fixed {
    top: 6.2rem;
    transition: $transition; } }
